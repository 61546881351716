<template>
  <div>
    <el-dialog title="进件" :visible.sync="dialogDetails" width="40%" center @close="close">
      <div class="wxDetails">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <i class="header-icon el-icon-edit-outline" style="margin-right:5px"></i>超级管理员信息
            </template>
            <div style="width:90%">
              <el-form
                :model="detailsForm"
                :rules="rules"
                ref="detailsForm"
                label-width="170px"
                class="demo-ruleForm"
              >
                <el-form-item label="姓名" prop="contactName">
                  <el-input v-model="detailsForm.contactName"></el-input>
                </el-form-item>
                <el-form-item label="身份证件号码" prop="contactIdNumber">
                  <el-input v-model="detailsForm.contactIdNumber"></el-input>
                </el-form-item>
                <el-form-item label="微信openid">
                  <el-input v-model="detailsForm.openid"></el-input>
                </el-form-item>
                <el-form-item label="联系手机" prop="mobilePhone">
                  <el-input v-model="detailsForm.mobilePhone"></el-input>
                </el-form-item>
                <el-form-item label="联系邮箱" prop="contactEmail">
                  <el-input v-model="detailsForm.contactEmail"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title">
              <i class="header-icon el-icon-edit-outline" style="margin-right:5px"></i>主体资料
            </template>
            <div style="width:90%">
              <el-form
                :model="detailsForm"
                :rules="ruleForm"
                ref="detailsForm"
                label-width="170px"
                class="demo-ruleForm"
              >
                <el-form-item label="主体类型" prop="subjectType">
                  <el-select
                    v-model="detailsForm.subjectType"
                    placeholder="请选择活动区域"
                    style="width:100%"
                    @change="getSettlementId(detailsForm.subjectType,true)"
                  >
                    <el-option
                      v-for="(item,i) in typeAll"
                      :key="i"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- 个体户 -->
                <el-form-item
                  label="营业执照照片"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INDIVIDUAL'||detailsForm.subjectType== 'SUBJECT_TYPE_ENTERPRISE'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'licenseCopy')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="licenseCopyUrl">
                    <img :src="licenseCopyUrl" alt="暂无图片" />
                  </div>
                </el-form-item>
                <el-form-item
                  label="注册号/统一社会信用代码"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INDIVIDUAL'||detailsForm.subjectType== 'SUBJECT_TYPE_ENTERPRISE'"
                >
                  <el-input v-model="detailsForm.licenseNumber" @blur="judgeLenght(detailsForm.licenseNumber)"></el-input>
                </el-form-item>
                <el-form-item
                  label="商户名称"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INDIVIDUAL'||detailsForm.subjectType== 'SUBJECT_TYPE_ENTERPRISE'"
                >
                  <el-input v-model="detailsForm.merchantName1"></el-input>
                </el-form-item>
                <el-form-item
                  label="个体户经营者/法人姓名"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INDIVIDUAL'||detailsForm.subjectType== 'SUBJECT_TYPE_ENTERPRISE'"
                >
                  <el-input v-model="detailsForm.legalPerson1"></el-input>
                </el-form-item>
                <!-- 党政/机关/事业单位和其他必填 -->

                <el-form-item
                  label="登记证书照片"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS' || detailsForm.subjectType=='SUBJECT_TYPE_OTHERS'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'certCopy')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="certCopyUrl">
                    <img :src="certCopyUrl" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  label="登记证书类型"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS' || detailsForm.subjectType=='SUBJECT_TYPE_OTHERS'"
                >
                  <el-select
                    v-model="detailsForm.certType"
                    placeholder="请选择活动区域"
                    style="width:100%"
                  >
                    <el-option
                      v-for="(item,i) in certType"
                      :key="i"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="证书编号"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS' || detailsForm.subjectType=='SUBJECT_TYPE_OTHERS'"
                >
                  <el-input v-model="detailsForm.certNumber" @blur="judgeLenght(detailsForm.certNumber)"></el-input>
                </el-form-item>
                <el-form-item
                  label="商户名称"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS' || detailsForm.subjectType=='SUBJECT_TYPE_OTHERS'"
                >
                  <el-input v-model="detailsForm.merchantName2"></el-input>
                </el-form-item>
                <el-form-item
                  label="注册地址"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS' || detailsForm.subjectType=='SUBJECT_TYPE_OTHERS'"
                >
                  <el-input v-model="detailsForm.companyAddress"></el-input>
                </el-form-item>
                <el-form-item
                  label="法定代表人姓名"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS' || detailsForm.subjectType=='SUBJECT_TYPE_OTHERS'"
                >
                  <el-input v-model="detailsForm.legalPerson2"></el-input>
                </el-form-item>
                <el-form-item
                  label="有效期限开始日期"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS' || detailsForm.subjectType=='SUBJECT_TYPE_OTHERS'"
                >
                  <el-input v-model="detailsForm.periodBegin"></el-input>
                </el-form-item>
                <el-form-item
                  label="有效期限结束日期"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS' || detailsForm.subjectType=='SUBJECT_TYPE_OTHERS'"
                >
                  <el-input v-model="detailsForm.periodEnd"></el-input>
                </el-form-item>

                <!--组织机构代码证照片  -->
                <el-form-item
                  label="组织机构代码证照片"
                  prop="name"
                  v-if="licenseNumberLength"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'organizationCopy')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="organizationCopyUrl">
                    <img :src="organizationCopyUrl" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  label="组织机构代码"
                  prop="name"
                  v-if="licenseNumberLength"
                >
                  <el-input v-model="detailsForm.organizationCode"></el-input>
                </el-form-item>
                <el-form-item
                  label="组织机构代码证有效期开始日期"
                  prop="name"
                  v-if="licenseNumberLength"
                >
                  <el-input v-model="detailsForm.orgPeriodBegin"></el-input>
                </el-form-item>
                <el-form-item
                  label="组织机构代码证有效期结束日期"
                  prop="name"
                  v-if="licenseNumberLength"
                >
                  <el-input v-model="detailsForm.orgPeriodEnd"></el-input>
                </el-form-item>
                <!-- 企业/党政机关/事业单位 -->
                <el-form-item
                  label="单位证明函照片"
                  prop="name"
                  v-if="detailsForm.subjectType=='SUBJECT_TYPE_INSTITUTIONS'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'certificateLetterCopy')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="certificateLetterCopyUrl">
                    <img :src="certificateLetterCopyUrl" alt />
                  </div>
                </el-form-item>

                <el-form-item label="证件类型" prop="name">
                  <el-select
                    v-model="detailsForm.idDocType"
                    placeholder="请选择活动区域"
                    style="width:100%"
                  >
                    <el-option
                      v-for="(item,i) in idDocType"
                      :key="i"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="detailsForm.idDocType=='IDENTIFICATION_TYPE_IDCARD'?'身份证人像面照片':'证件照片'"
                  prop="name"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'idCardCopy1')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="idCardCopy1Url">
                    <img :src="idCardCopy1Url" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  label="身份证国徽面照片"
                  prop="name"
                  v-if="detailsForm.idDocType=='IDENTIFICATION_TYPE_IDCARD'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'idCardNational1')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="idCardNational1Url">
                    <img :src="idCardNational1Url" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  :label="detailsForm.idDocType=='IDENTIFICATION_TYPE_IDCARD'?'身份证姓名':'证件姓名'"
                  prop="name"
                >
                  <el-input v-model="detailsForm.idCardName"></el-input>
                </el-form-item>
                <el-form-item
                  :label="detailsForm.idDocType=='IDENTIFICATION_TYPE_IDCARD'?'身份证号码':'证件号码'"
                  prop="name"
                >
                  <el-input v-model="detailsForm.idCardNumber"></el-input>
                </el-form-item>
                <el-form-item label="证件有效期开始时间" prop="name">
                  <el-input v-model="detailsForm.cardPeriodBegin"></el-input>
                </el-form-item>
                <el-form-item label="证件有效期结束时间" prop="name">
                  <el-input v-model="detailsForm.cardPeriodEnd"></el-input>
                </el-form-item>
                <el-form-item
                  label="经营者/法人是否为受益人"
                  prop="owner"
                >
                 <el-radio-group v-model="detailsForm.owner" style="margin-top:12px">
                   <el-radio label="true">是</el-radio>
                   <el-radio label="false">否</el-radio>
                 </el-radio-group>
                </el-form-item>

           
                <!-- 最终受益人 -->
                <el-form-item label="证件类型" prop="name" v-if="detailsForm.owner=='false'">
                  <el-select v-model="detailsForm.idType" placeholder="请选择活动区域" style="width:100%">
                    <el-option
                      v-for="(item,i) in idType"
                      :key="i"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="身份证人像面照片"
                  prop="name"
                  v-if="detailsForm.owner=='false'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'idCardCopy2')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="idDocCopy2Url">
                    <img :src="idDocCopy2Url" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  label="身份证国徽面照片"
                  prop="name"
                  v-if="detailsForm.owner=='false'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'idCardNational2')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="idCardNational2Url">
                    <img :src="idCardNational2Url" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  label="证件照片"
                  prop="name"
                  v-if="detailsForm.owner=='false'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'idDocCopy2')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="idDocCopy2Url">
                    <img :src="idDocCopy2Url" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  label="受益人姓名"
                  prop="name"
                  v-if="detailsForm.owner=='false'"
                >
                  <el-input v-model="detailsForm.name"></el-input>
                </el-form-item>
                <el-form-item
                  label="证件号码"
                  prop="name"
                  v-if="detailsForm.owner=='false'"
                >
                  <el-input v-model="detailsForm.idNumber"></el-input>
                </el-form-item>
                <el-form-item
                  label="开始时间"
                  prop="name"
                  v-if="detailsForm.owner=='false'"
                >
                  <el-input v-model="detailsForm.idPeriodBegin"></el-input>
                </el-form-item>
                <el-form-item
                  label="结束时间"
                  prop="name"
                  v-if="detailsForm.owner=='false'"
                >
                  <el-input v-model="detailsForm.idPeriodEnd"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template slot="title">
              <i class="header-icon el-icon-edit-outline" style="margin-right:5px"></i>经营资料
            </template>
            <div style="width:90%">
              <el-form
                :model="detailsForm"
                :rules="ruleShop"
                ref="detailsForm"
                label-width="170px"
                class="demo-ruleForm"
              >
                <el-form-item label="商户简称" prop="merchantShortname">
                  <el-input v-model="detailsForm.merchantShortname"></el-input>
                </el-form-item>
                <el-form-item label="客服电话" prop="servicePhone">
                  <el-input v-model="detailsForm.servicePhone"></el-input>
                </el-form-item>
                <el-form-item label="经营场景类型" prop="salesScenesType">
                  <el-select
                    v-model="detailsForm.salesScenesType"
                    placeholder="请选择活动区域"
                    style="width:100%"
                  >
                    <el-option
                      v-for="(item,i) in salesScenesType"
                      :key="i"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="门店名称" v-if="detailsForm.salesScenesType=='SALES_SCENES_STORE'">
                  <el-input v-model="detailsForm.bizStoreName"></el-input>
                </el-form-item>
                <el-form-item
                  label="门店省市编码"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_STORE'"
                >
                <el-select
                    v-model="detailsForm.bizAddressCode"
                    placeholder="请输入关键字搜索选择"
                    filterable
                    remote
                    style="width:100%"
                    :remote-method="getbankAddress"
                  >
                    <el-option
                      v-for="(item,i) in  areaCodeAll"
                      :key="i"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                  <!-- <el-input v-model="detailsForm.bizAddressCode" filterable
                    remote
                    style="width:100%"
                    :remote-method="getbankAddress"></el-input> -->
                </el-form-item>
                <el-form-item label="门店地址" v-if="detailsForm.salesScenesType=='SALES_SCENES_STORE'">
                  <el-input v-model="detailsForm.bizStoreAddress"></el-input>
                </el-form-item>
                <el-form-item
                  label="门店门头照片"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_STORE'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'storeEntrancePic')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="storeEntrancePicUrl">
                    <img :src="storeEntrancePicUrl" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  label="店内环境照片"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_STORE'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'indoorPic')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="indoorPicUrl">
                    <img :src="indoorPicUrl" alt />
                  </div>
                </el-form-item>
                <el-form-item
                  label="线下场所对应的商家APPID"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_STORE'"
                >
                  <el-input v-model="detailsForm.bizSubAppid"></el-input>
                </el-form-item>

                <el-form-item
                  label="服务商公众号APPID"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_MP'"
                >
                  <el-input v-model="detailsForm.mpAppid"></el-input>
                </el-form-item>
                <el-form-item
                  label="商家公众号APPID"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_MP'"
                >
                  <el-input v-model="detailsForm.mpSubAppid"></el-input>
                </el-form-item>
                <el-form-item
                  label="公众号页面截图"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_MP'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'mpPics')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="mpPicsUrl">
                    <img :src="mpPicsUrl" alt />
                  </div>
                </el-form-item>
                <!-- 小程序场景 -->
                <el-form-item
                  label="服务商小程序APPID"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_MINI_PROGRAM'"
                >
                  <el-input v-model="detailsForm.miniProgramAppid "></el-input>
                </el-form-item>
                <el-form-item
                  label="商家小程序APPID"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_MINI_PROGRAM'"
                >
                  <el-input v-model="detailsForm.miniProgramSubAppid"></el-input>
                </el-form-item>
                <el-form-item
                  label="小程序截图"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_MINI_PROGRAM'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'miniProgramPics')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="miniProgramPicsUrl">
                    <img :src="miniProgramPicsUrl" alt />
                  </div>
                </el-form-item>
                <!-- app场景 -->
                <el-form-item
                  label="服务商应用APPID"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_APP'"
                >
                  <el-input v-model="detailsForm.appAppid"></el-input>
                </el-form-item>
                <el-form-item
                  label="商家应用APPID"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_APP'"
                >
                  <el-input v-model="detailsForm.appSubAppid"></el-input>
                </el-form-item>
                <el-form-item
                  label="APP截图"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_APP'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'appPics')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="appPicsUrl">
                    <img :src="appPicsUrl" alt />
                  </div>
                </el-form-item>
                <!--网站场景 -->
                <el-form-item
                  label="互联网网站域名需ICP备案"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_WEB'"
                >
                  <el-input v-model="detailsForm.domain"></el-input>
                </el-form-item>
                <el-form-item
                  label="网站授权函"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_WEB'"
                >
                  <el-input v-model="detailsForm.webAuthorisation"></el-input>
                </el-form-item>
                <el-form-item
                  label="互联网网站对应的商家APPID"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_WEB'"
                >
                  <el-input v-model="detailsForm.webAppid"></el-input>
                </el-form-item>
                <el-form-item
                  label="企业微信场景"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_WEB'"
                >
                  <el-input v-model="detailsForm.name"></el-input>
                </el-form-item>
                <el-form-item
                  label="商家企业微信CorpID"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_WEWORK'"
                >
                  <el-input v-model="detailsForm.subCorpId"></el-input>
                </el-form-item>
                <el-form-item
                  label="企业微信页面截图"
                  prop="name"
                  v-if="detailsForm.salesScenesType=='SALES_SCENES_WEWORK'"
                >
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'weworkPics')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="weworkPicsUrl">
                    <img :src="weworkPicsUrl" alt />
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
          <el-collapse-item name="4">
            <template slot="title">
              <i class="header-icon el-icon-edit-outline" style="margin-right:5px"></i>结算规则
            </template>
            <div style="width:90%">
              <el-form
                :model="detailsForm"
                ref="detailsForm"
                :rules="ruletype"
                label-width="170px"
                class="demo-ruleForm"
              >
                <el-form-item label="所属行业" prop="typeName">
                  <div style="display:flex">
                    <el-select
                      v-model="detailsForm.typeName"
                      placeholder="请选择活动区域"
                      style="width:50%"
                      @change="getSettlementId(detailsForm.typeName,false)"
                    >
                      <el-option
                        v-for="(item,i) in settlementRules"
                        :key="i"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                    <el-select
                      v-model="detailsForm.settlementId"
                      placeholder="请选择活动区域"
                      style="width:50%;margin-left:10px"
                      @change="setQualification(detailsForm.settlementId)"
                    >
                      <el-option
                        v-for="(item,i) in settlementId"
                        :key="i"
                        :label="item.industry"
                        :value="item.industry"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                 <div class="requireMaterial">特殊资质：{{requireMaterial}}</div>
                <el-form-item label="特殊资质图片" prop="img">
                  <el-upload
                    :limit="4"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'qualifications')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="qualificationsUrl">
                    <img :src="qualificationsUrl" alt />
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
          <el-collapse-item name="5">
            <template slot="title">
              <i class="header-icon el-icon-edit-outline" style="margin-right:5px"></i>结算银行账户
            </template>
            <div style="width:90%">
              <el-form
                :rules="ruleBlank"
                :model="detailsForm"
                ref="detailsForm"
                label-width="170px"
                class="demo-ruleForm"
              >
                <el-form-item label="账户类型" prop="bankAccountType">
                  <el-select
                    v-model="detailsForm.bankAccountType"
                    placeholder="请选择活动区域"
                    style="width:100%"
                  >
                    <el-option
                      v-for="(item,i) in bankAccountType"
                      :key="i"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开户名称" prop="accountName">
                  <el-input v-model="detailsForm.accountName"></el-input>
                </el-form-item>
                <el-form-item label="开户银行" prop="accountBank">
                  <el-select
                    v-model="detailsForm.accountBank"
                    placeholder="请选择活动区域"
                    style="width:100%"
                  >
                    <el-option
                      v-for="(item,i) in accountBank"
                      :key="i"
                      :label="item.name"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开户银行省市编码" prop="bankAddressCode">
                  <el-select
                    v-model="detailsForm.bankAddressCode"
                    placeholder="请输入关键字搜索选择"
                    filterable
                    remote
                    style="width:100%"
                    :remote-method="getbankAddress"
                  >
                    <el-option
                      v-for="(item,i) in  areaCodeAll"
                      :key="i"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开户银行全称(含支行)" prop="bankName">
                  <el-select
                    v-model="detailsForm.bankName"
                    placeholder="请输入关键字搜索选择"
                    filterable
                    remote
                    style="width:100%"
                    @change="getBankName(detailsForm.bankName)"
                    clearable
                    @clear="clearId"
                    :remote-method="getbankBranchId"
                  >
                    <el-option
                      v-for="(item,i) in  bankBranchId"
                      :key="i"
                      :label="item.name"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开户银行联行号" prop="bankBranchId">
                  <el-input v-model="detailsForm.bankBranchId"></el-input>
                </el-form-item>
                <el-form-item label="银行账号" prop="accountNumber">
                  <el-input v-model="detailsForm.accountNumber "></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
          <el-collapse-item name="6">
            <template slot="title">
              <i class="header-icon el-icon-edit-outline" style="margin-right:5px"></i>补充材料
            </template>
            <div style="width:90%">
              <el-form
                :rules="businessAdditionMsgs"
                :model="detailsForm"
                ref="detailsForm"
                label-width="170px"
                class="demo-ruleForm"
              >
                <el-form-item label="法人开户承诺函">
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'legalPersonCommitment')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="legalPersonCommitmentUrl">
                    <img :src="legalPersonCommitmentUrl" alt />
                  </div>
                </el-form-item>
                <el-form-item label="补充材料">
                  <el-upload
                    :limit="1"
                    :multiple="false"
                    :action="action"
                    name="image"
                    list-type="picture-card"
                    :on-success="function (res, file) { return successData(res, file, 'businessAdditionPics')}"
                    :data="uploadData"
                    :headers="headers"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img" v-if="businessAdditionPicsUrl">
                    <img :src="businessAdditionPicsUrl" alt />
                  </div>
                </el-form-item>
                <el-form-item label="补充说明" prop="businessAdditionMsg">
                  <el-input v-model="detailsForm.businessAdditionMsg "></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close('detailsForm')">取 消</el-button>
        <el-button type="primary" @click="confirmSubmit('detailsForm')">保 存</el-button>
        <el-button type="primary" @click="wxSubmit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "../../../utils/auth.js";
import { Logger } from 'runjs/lib/common';
export default {
  props: ["dialogWxDetails", "shopInf", "applyWxTb"],
  data() {
    return {
      headers: {
        token: getToken().Token,
        userId: getToken().userId
      },
      rules: {
        contactName: [
          { required: true, message: "请填写姓名", trigger: "blur" }
        ],
        contactIdNumber: [
          {
            required: true,
            message: "身份证号和微信openid任选一样填写",
            trigger: "blur"
          }
        ],
        mobilePhone: [
          { required: true, message: "请填写手机号", trigger: "blur" }
        ],
        contactEmail: [
          { required: true, message: "请填写邮箱", trigger: "blur" }
        ]
      },
      ruleForm: {
        subjectType: [
          { required: true, message: "请填写姓名", trigger: "change" }
        ]
      },
      ruleShop: {
        merchantShortname: [
          { required: true, message: "请填写商户", trigger: "blur" }
        ],
        servicePhone: [
          { required: true, message: "请填写客服电话", trigger: "blur" }
        ],
        salesScenesType: [
          { required: true, message: "请填写经营场景类型", trigger: "change" }
        ]
      },
      ruletype: {
        typeName: [
          { required: true, message: "请选择行业", trigger: "change" }
        ],
        img: [{ required: true, message: "请上传图片", trigger: "change" }]
      },
      ruleBlank: {
        bankAccountType: [
          { required: true, message: "请选择账户类型", trigger: "change" }
        ],
        accountNumber: [
          { required: true, message: "请填写银行账号", trigger: "blur" }
        ],
        bankBranchId: [
          { required: true, message: "请填写开户银行联行号", trigger: "blur" }
        ],
        bankName: [
          { required: true, message: "请选择开户银行全称", trigger: "blur" }
        ],
        accountName: [
          { required: true, message: "请填写开户银行全称", trigger: "blur" }
        ],
        bankAddressCode: [
          { required: true, message: "请选择开户银行省市编码", trigger: "blur" }
        ],
        accountBank: [
          { required: true, message: "请选择开户银行", trigger: "change" }
        ]
      },
      businessAdditionMsgs: {
        businessAdditionMsg: [
          { required: true, message: "请填写补充说明", trigger: "change" }
        ]
      },
      action: "api/mchwx/imageUpload", //图片上传地址(打包把API更改为地址)
      dialogDetails: false,
      activeNames: ["1","2","3","4","5","6"],
      detailsForm: {
        contact_name: "",
        contact_id_number: "",
        openid: "",
        mobile_phone: "",
        contact_email: "",
        subject_type: "",
        license_copy: "", //营业执照照片
        license_number: "",
        merchant_name_1: "",
        legal_person_1: "",
        cert_copy: "", //登记证书照片
        cert_type: "",
        cert_number: "",
        merchant_name_2: "",
        company_address: "",
        legal_person_2: "",
        period_begin: "",
        period_end: "",
        organization_copy: "", //组织机构代码证照片
        organization_code: "",
        org_period_begin: "",
        org_period_end: "",
        certificate_letter_copy: "", //单位证明函照片
        id_doc_type: "",
        id_card_name: "",
        id_card_number: "",
        card_period_begin: "",
        id_card_copy_1: "", //身份证人像面照片
        id_card_national_1: "", //身份证国徽面照片
        card_period_end: "",
        id_doc_copy_1: "", //证件照片
        id_doc_name: "",
        id_doc_number: "",
        doc_period_begin: "",
        doc_period_end: "",
        owner: "",
        id_type: "",
        id_card_copy_2: "", //身份证人像面照片
        id_card_national_2: "", //身份证国徽面照片
        id_doc_copy_2: "", //证件照片
        indoor_pic: "", //店内环境照片
        name: "",
        id_number: "",
        id_period_begin: "",
        id_period_end: "",
        merchant_shortname: "",
        service_phone: "",
        sales_scenes_type: "",
        biz_store_name: "",
        biz_address_code: "",
        biz_store_address: "",
        store_entrance_pic: "", //门店门头照片
        biz_sub_appid: "",
        mp_appid: "",
        mp_sub_appid: "",
        mp_pics: "", //公众号页面截图
        mini_program_appid: "",
        mini_program_sub_appid: "",
        mini_program_pics: "", //小程序截图,
        app_appid: "",
        app_sub_appid: "",
        app_pics: "", //APP截图,
        domain: "",
        web_authorisation: "", //网站授权函
        web_appid: "",
        sub_corp_id: "",
        wework_pics: "", //企业微信页面截图
        settlement_id: "",
        qualification_type: "",
        qualifications: "", //特殊资质图片
        activities_id: "",
        activities_rate: "",
        activities_additions: "", //优惠费率活动补充材料
        bank_account_type: "",
        account_name: "",
        account_bank: "",
        bank_address_code: "",
        bank_branch_id: "",
        bank_name: "",
        account_number: "",
        legal_person_commitment: "", //法人开户承诺函
        // legal_person_video: "", //法人开户意愿视频
        business_addition_pics: "", //补充材料
        business_addition_msg: ""
      }, //进件表单值
      typeAll: [], //所有主体类型
      salesScenesType: [],
      idType: [],
      idDocType: [],
      certType: [],
      bankAccountType: [],
      accountBank: [],
      areaCodeAll: [], //所有的省市
      bankBranchId: [],
      applyDesc: {},
      settlementRules: [], //结算规则
      settlementId: [],
      subjectType: "",
      requireMaterial: "",

      // 所有上传的图片地址
      businessAdditionPicsUrl: "",
      certificateLetterCopyUrl: "",
      idCardCopy1Url: "",
      idDocCopy2Url: "",
      legalPersonCommitmentUrl: "",
      licenseCopyUrl: "",
      miniProgramPicsUrl: "",
      organizationCopyUrl: "",
      qualificationsUrl: "",
      certCopyUrl: "",
      weworkPicsUrl: "",
      idCardNational2Url: "",
      idCardNational1Url: "",
      appPicsUrl: "",
      storeEntrancePicUrl: "",
      indoorPicUrl: "",
      mpPicsUrl: "",


      licenseNumberLength:false
    };
  },
  created() {
    this.getTypeAll();
  },
  watch: {
    applyWxTb() {
      this.detailsForm = this.applyWxTb;
      this.getImgAll();
    },
    dialogWxDetails() {
      this.dialogDetails = this.dialogWxDetails;
    }
  },
  computed: {
    uploadData() {
      //文件上传数据
      const { id, projectContractorId } = this.shopInf; //点击的支付宝目标
      return { projectId: projectContractorId, shopId: id };
    }
  },
  methods: {
    // 查询所有主体类型
    getTypeAll() {
      this.typeAll = [];
      this.$axios.get("/mchwx/applyEnum").then(res => {
        if (res.data.state == 1) {
          this.applyDesc = res.data.applyDesc;
          const subject_type = this.applyDesc.subject_type;
          const sales_scenes_type = this.applyDesc.sales_scenes_type;
          const id_type = this.applyDesc.id_type;
          const id_doc_type = this.applyDesc.id_doc_type;
          const cert_type = this.applyDesc.cert_type;
          const bank_account_type = this.applyDesc.bank_account_type;
          const account_bank = this.applyDesc.account_bank;
          for (let key in subject_type) {
            let obj = {};
            obj.name = subject_type[key];
            obj.value = key;
            this.typeAll = [obj, ...this.typeAll];
          }
          for (let key in sales_scenes_type) {
            let obj2 = {};
            obj2.name = sales_scenes_type[key];
            obj2.value = key;
            this.salesScenesType = [obj2, ...this.salesScenesType];
          }
          for (let key in id_type) {
            let obj3 = {};
            obj3.name = id_type[key];
            obj3.value = key;
            this.idType = [obj3, ...this.idType];
          }
          for (let key in id_doc_type) {
            let obj4 = {};
            obj4.name = id_doc_type[key];
            obj4.value = key;
            this.idDocType = [obj4, ...this.idDocType];
          }
          for (let key in cert_type) {
            let obj5 = {};
            obj5.name = cert_type[key];
            obj5.value = key;
            this.certType = [obj5, ...this.certType];
          }
          for (let key in bank_account_type) {
            let obj6 = {};
            obj6.name = bank_account_type[key];
            obj6.value = key;
            this.bankAccountType = [obj6, ...this.bankAccountType];
          }
          for (let key in account_bank) {
            let obj7 = {};
            obj7.name = account_bank[key];
            obj7.value = key;
            this.accountBank = [obj7, ...this.accountBank].reverse();
          }
        }
      });
    },
    // 确认编辑
    confirmSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { mchId, shopId, projectContractorId, id } = this.applyWxTb;
          const {
            contactName,
            contactIdNumber,
            openid,
            mobilePhone,
            contactEmail,
            subjectType,
            licenseCopy, //营业执照照片
            licenseNumber,
            merchantName1,
            legalPerson1,
            certCopy, //登记证书照片
            certType,
            certNumber,
            merchantName2,
            companyAddress,
            legalPerson2,
            periodBegin,
            periodEnd,
            organizationCopy, //组织机构代码证照片
            organizationCode,
            orgPeriodBegin,
            orgPeriodEnd,
            certificateLetterCopy, //单位证明函照片
            idDocType,
            idCardName,
            idCardNumber,
            cardPeriodBegin,
            idCardCopy1, //身份证人像面照片
            idCardNational1, //身份证国徽面照片
            cardPeriodEnd,
            idDocCopy1, //证件照片
            idDocName,
            idDocNumber,
            docPeriodBegin,
            docPeriodEnd,
            owner,
            idType,
            idCardCopy2, //身份证人像面照片
            idCardNational2, //身份证国徽面照片
            idDocCopy2, //证件照片
            indoorPic, //店内环境照片
            name,
            idNumber,
            idPeriodBegin,
            idPeriodEnd,
            merchantShortname,
            servicePhone,
            salesScenesType,
            bizStoreName,
            bizAddressCode,
            bizStoreAddress,
            storeEntrancePic, //门店门头照片
            bizSubAppid,
            mpAppid,
            mpSubAppid,
            mpPics, //公众号页面截图
            miniProgramAppid,
            miniProgramSubAppid,
            miniProgramPics, //小程序截图,
            appAppid,
            appSubAppid,
            appPics, //APP截图,
            domain,
            webAuthorisation, //网站授权函
            webAppid,
            subCorpId,
            weworkPics, //企业微信页面截图
            settlementId,
            qualificationType,
            qualifications, //特殊资质图片
            activitiesId,
            activitiesRate,
            activitiesAdditions, //优惠费率活动补充材料
            bankAccountType,
            accountName,
            accountBank,
            bankAddressCode,
            bankBranchId,
            bankName,
            accountNumber,
            legalPersonCommitment, //法人开户承诺函
            // legal_person_video, //法人开户意愿视频
            businessAdditionPics, //补充材料
            businessAdditionMsg
          } = this.detailsForm;
          let wxParams = {
            applyId: id,
            mchId,
            shopId,
            projectId: projectContractorId,
            contact_name: contactName,
            contact_id_number: contactIdNumber,
            openid,
            mobile_phone: mobilePhone,
            contact_email: contactEmail,
            subject_type: subjectType,
            license_copy: licenseCopy, //营业执照照片
            license_number: licenseNumber,
            merchant_name_1: merchantName1,
            legal_person_1: legalPerson1,
            cert_copy: certCopy, //登记证书照片
            cert_type: certType,
            cert_number: certNumber,
            merchant_name_2: merchantName2,
            company_address: companyAddress,
            legal_person_2: legalPerson2,
            period_begin: periodBegin,
            period_end: periodEnd,
            organization_copy: organizationCopy, //组织机构代码证照片
            organization_code: organizationCode,
            org_period_begin: orgPeriodBegin,
            org_period_end: orgPeriodEnd,
            certificate_letter_copy: certificateLetterCopy, //单位证明函照片
            id_doc_type: idDocType,
            id_card_name: idCardName,
            id_card_number: idCardNumber,
            card_period_begin: cardPeriodBegin,
            id_card_copy_1: idCardCopy1, //身份证人像面照片
            id_card_national_1: idCardNational1, //身份证国徽面照片
            card_period_end: cardPeriodEnd,
            id_doc_copy_1: idDocCopy1, //证件照片
            id_doc_name: idDocName,
            id_doc_number: idDocNumber,
            doc_period_begin: docPeriodBegin,
            doc_period_end: docPeriodEnd,
            owner,
            id_type: idType,
            id_card_copy_2: idCardCopy2, //身份证人像面照片
            id_card_national_2: idCardNational2, //身份证国徽面照片
            id_doc_copy_2: idDocCopy2, //证件照片
            indoor_pic: indoorPic, //店内环境照片
            name,
            id_number: idNumber,
            id_period_begin: idPeriodBegin,
            id_period_end: idPeriodEnd,
            merchant_shortname: merchantShortname,
            service_phone: servicePhone,
            sales_scenes_type: salesScenesType,
            biz_store_name: bizStoreName,
            biz_address_code: bizAddressCode,
            biz_store_address: bizStoreAddress,
            store_entrance_pic: storeEntrancePic, //门店门头照片
            biz_sub_appid: bizSubAppid,
            mp_appid: mpAppid,
            mp_sub_appid: mpSubAppid,
            mp_pics: mpPics, //公众号页面截图
            mini_program_appid: miniProgramAppid,
            mini_program_sub_appid: miniProgramSubAppid,
            mini_program_pics: miniProgramPics, //小程序截图,
            app_appid: appAppid,
            app_sub_appid: appSubAppid,
            app_pics: appPics, //APP截图,
            domain,
            web_authorisation: webAuthorisation, //网站授权函
            web_appid: webAppid,
            sub_corp_id: subCorpId,
            wework_pics: weworkPics, //企业微信页面截图
            settlement_id: settlementId,
            qualification_type: qualificationType,
            qualifications, //特殊资质图片
            activities_id: activitiesId,
            activities_rate: activitiesRate,
            activities_additions: activitiesAdditions, //优惠费率活动补充材料
            bank_account_type: bankAccountType,
            account_name: accountName,
            account_bank: accountBank,
            bank_address_code: bankAddressCode,
            bank_branch_id: bankBranchId,
            bank_name: bankName,
            account_number: accountNumber,
            legal_person_commitment: legalPersonCommitment, //法人开户承诺函
            // legal_person_video, //法人开户意愿视频
            business_addition_pics: businessAdditionPics, //补充材料
            business_addition_msg: businessAdditionMsg
          };
          this.$axios
            .get("/mchwx/applyEdit", { params: wxParams })
            .then(res => {
              if (res.data.state == 1) {
                this.$emit('getDetailsInformation');
                this.DeleteSuccess(res.data.msg);
              } else {
                this.DeleteFailure(res.data.msg);
              }
            });
        } else {
          this.$message.error("请填写完整信息");
        }
      });
    },
    // 提交
    wxSubmit(){
      const { mchId, shopId, projectContractorId, id } = this.applyWxTb;
      this.$axios.get('/mchwx/applyCommit',{params:{
        shopId, projectId:projectContractorId,applyId:id,mchId
      }}).then(res=>{
        if(res.data.state==1){
          this.DeleteSuccess(res.data.msg);
        }else{
          this.DeleteFailure(res.data.msg+res.data.desc);
        }
      })
    },
    // 查询所有上传图片
    getImgAll() {
      const {
        businessAdditionPics,
        certificateLetterCopy,
        idCardCopy1,
        idDocCopy2,
        legalPersonCommitment,
        licenseCopy,
        miniProgramPics,
        organizationCopy,
        qualifications,
        certCopy,
        weworkPics,
        idCardNational2,
        idCardNational1,
        appPics,
        storeEntrancePic,
        indoorPic,
        mpPics
      } = this.detailsForm;
      if (mpPics !== "") {
        this.getImg(mpPics).then(res => {
          if (res.state == 1) {
            this.mpPicsUrl = res.url;
          }
        });
      }
      if (indoorPic !== "") {
        this.getImg(indoorPic).then(res => {
          if (res.state == 1) {
            this.indoorPicUrl = res.url;
          }
        });
      }
      if (storeEntrancePic !== "") {
        this.getImg(storeEntrancePic).then(res => {
          if (res.state == 1) {
            this.storeEntrancePicUrl = res.url;
          }
        });
      }
      if (appPics !== "") {
        this.getImg(appPics).then(res => {
          if (res.state == 1) {
            this.appPicsUrl = res.url;
          }
        });
      }
      if (idCardNational1 !== "") {
        this.getImg(idCardNational1).then(res => {
          if (res.state == 1) {
            this.idCardNational1Url = res.url;
          }
        });
      }
      if (idCardNational2 !== "") {
        this.getImg(idCardNational2).then(res => {
          if (res.state == 1) {
            this.idCardNational2Url = res.url;
          }
        });
      }
      if (weworkPics !== "") {
        this.getImg(weworkPics).then(res => {
          if (res.state == 1) {
            this.weworkPicsUrl = res.url;
          }
        });
      }
      if (certCopy !== "") {
        this.getImg(certCopy).then(res => {
          if (res.state == 1) {
            this.certCopyUrl = res.url;
          }
        });
      }
      if (businessAdditionPics !== "") {
        this.getImg(businessAdditionPics).then(res => {
          if (res.state == 1) {
            this.businessAdditionPicsUrl = res.url;
          }
        });
      }
      if (certificateLetterCopy !== "") {
        this.getImg(certificateLetterCopy).then(res => {
          if (res.state == 1) {
            this.certificateLetterCopyUrl = res.url;
          }
        });
      }
      if (idCardCopy1 !== "") {
        this.getImg(idCardCopy1).then(res => {
          if (res.state == 1) {
            this.idCardCopy1Url = res.url;
          }
        });
      }
      if (idDocCopy2 !== "") {
        this.getImg(idDocCopy2).then(res => {
          if (res.state == 1) {
            this.idDocCopy2Url = res.url;
          }
        });
      }
      if (legalPersonCommitment !== "") {
        this.getImg(legalPersonCommitment).then(res => {
          if (res.state == 1) {
            this.legalPersonCommitmentUrl = res.url;
          }
        });
      }
      if (licenseCopy !== "") {
        this.getImg(licenseCopy).then(res => {
          if (res.state == 1) {
            this.licenseCopyUrl = res.url;
          }
        });
      }
      if (miniProgramPics !== "") {
        this.getImg(miniProgramPics).then(res => {
          if (res.state == 1) {
            this.miniProgramPicsUrl = res.url;
          }
        });
      }
      if (organizationCopy !== "") {
        this.getImg(organizationCopy).then(res => {
          if (res.state == 1) {
            this.organizationCopyUrl = res.url;
          }
        });
      }
      if (qualifications !== "") {
        this.getImg(qualifications).then(res => {
          if (res.state == 1) {
            this.qualificationsUrl = res.url;
          }
        });
      }
    },
    async getImg(img) {
      const { id, projectContractorId } = this.shopInf; //点击的支付宝目标
      let imgUrl = await this.$axios.get("/mchwx/imageDownload", {
        params: {
          shopId: id,
          projectId: projectContractorId,
          imgName: img
        }
      });
      return imgUrl.data;
    },
    //查询对应的地址
    getbankAddress(address) {
      this.$axios
        .get("/mchwx/areaCode", { params: { name: address } })
        .then(res => {
          if (res.data.state == 1) {
            const areaCode = res.data.areaCode;
            for (let key in areaCode) {
              let objAreaCode = {};
              objAreaCode.name = areaCode[key];
              objAreaCode.value = key;
              this.areaCodeAll = [objAreaCode, ...this.areaCodeAll];
            }
          }
        });
    },
    getbankBranchId(address) {
      this.$axios
        .get("/mchwx/bankCode", { params: { name: address } })
        .then(res => {
          if (res.data.state == 1) {
            const bankCode = res.data.bankCode;
            for (let key in bankCode) {
              let obj8 = {};
              obj8.name = bankCode[key];
              obj8.value = key;
              this.bankBranchId = [obj8, ...this.bankBranchId];
            }
          }
        });
    },
    getBankName(data) {
      this.bankBranchId.forEach(item => {
        if (item.name == data) {
          this.detailsForm.bankBranchId = item.value;
          console.log();
        }
      });
    },
    getSettlementId(data, sign) {
      let params;
      if (sign) {
        this.typeAll.forEach(item => {
          if (item.value === data) {
            this.subjectType = item.name;
            params = { subject_type: item.name };
          }
        });
      } else {
        params = { subject_type: this.subjectType, business_range: data };
      }
      this.$axios.get("/mchwx/settlementId", { params: params }).then(res => {
        if (res.data.state == 1) {
          const data = res.data.settlementRules;
          if (sign) {
            this.settlementRules = new Set(
              data.map(item => {
                return item.businessRange;
              })
            );
          } else {
            this.settlementId = new Set(data);
          }
        }
      });
    },
    setQualification(data) {
      this.detailsForm.qualification_type = this.detailsForm.settlement_id;
      this.settlementId.forEach(item => {
        if (item.industry === data) {
          this.requireMaterial = item.requireMaterial;
        }
      });
    },
    clearId() {
      this.bankBranchId = [];
      this.detailsForm.bank_branch_id = "";
    },
    // 关闭弹框
    close(formName) {
      this.$emit("coloseWxDialog");
      this.$refs.detailsForm.resetFields();
    },
    successData(res, fileList, params) {
      //params为区别是哪一个上传的图片
      if (res.state === 1) {
        if (params == "certCopy") {
          this.detailsForm.certCopy = res.imgName;
        } else if (params == "licenseCopy") {
          this.detailsForm.licenseCopy = res.imgName;
        } else if (params == "idCardCopy1") {
          this.detailsForm.idCardCopy1 = res.imgName;
        } else if (params == "organizationCopy") {
          this.detailsForm.organizationCopy = res.imgName;
        } else if (params == "certificateLetterCopy") {
          this.detailsForm.certificateLetterCopy = res.imgName;
        } else if (params == "idCardNational1") {
          this.detailsForm.idCardNational1 = res.imgName;
        } else if (params == "idCardCopy2") {
          this.detailsForm.idCardCopy2 = res.imgName;
        } else if (params == "idCardNational2") {
          this.detailsForm.idCardNational2 = res.imgName;
        } else if (params == "idDocCopy2") {
          this.detailsForm.idDocCopy2 = res.imgName;
        } else if (params == "storeEntrancePic") {
          this.detailsForm.storeEntrancePic = res.imgName;
        } else if (params == "indoorPic") {
          this.detailsForm.indoorPic = res.imgName;
        } else if (params == "mpPics") {
          this.detailsForm.mpPics = res.imgName;
        } else if (params == "miniProgramPics") {
          this.detailsForm.miniProgramPics = res.imgName;
        } else if (params == "appPics") {
          this.detailsForm.appPics = res.imgName;
        } else if (params == "weworkPics") {
          this.detailsForm.weworkPics = res.imgName;
        } else if (params == "qualifications") {
          this.detailsForm.qualifications = res.imgName;
        } else if (params == "activitiesAdditions") {
          this.detailsForm.activitiesAdditions = res.imgName;
        } else if (params == "businessAdditionPics") {
          this.detailsForm.businessAdditionPics = res.imgName;
        } else if (params == "legalPersonCommitment") {
          this.detailsForm.legalPersonCommitment = res.imgName;
        }
        this.DeleteSuccess(res.msg);
      } else {
        this.DeleteFailure(res.msg);
      }
    },
    judgeLenght(data){
      console.log(data.length);
      data.length<18 && (this.licenseNumberLength=true);
      data.length>17 && (this.licenseNumberLength=false);
    },
    //成功提示
    DeleteSuccess(data) {
      this.$message({
        showClose: true,
        message: data,
        type: "success"
      });
    },
    //失败提示
    DeleteFailure(data) {
      this.$message.error(data);
    }
  }
};
</script>

<style lang="scss" scoped>
.wxDetails {
  height: 500px;
  overflow-y: scroll;
}
.requireMaterial {
  line-height: 20px;
  font-size: 12px;
  color: red;
  margin-bottom: 22px;
  padding-left: 105px;
}
.img {
  width: 148px;
  height: 148px;
  border: 1px dashed #ccc;
  margin-left: 10px;
  border-radius: 8px;
}
.img > img:first-child {
  width: 100%;
  height: 100%;
   border-radius: 8px;
}
.el-form-item__content {
  display: flex;
}
/deep/.el-form-item__content {
  display: flex;
}
</style>