<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!-- 商户信息 -->
      <el-col :span="10">
        <el-card shadow="hover">
          <div class="engineer">
            <div>商户信息</div>
            <div class="infromations">
              <div>商户名：{{shopInf.shopName}}</div>
              <div>商户号：{{shopInf.id}}</div>
              <div style="margin-bottom:76px">接口密钥：{{shopInf.secretKey}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <!-- 基本信息 -->
      <el-col :span="10">
        <el-card shadow="hover">
          <div class="engineer">
            <div>基本信息</div>
            <div class="infromations">
              <div class="Basic_02">
                <div>地址：{{shopInf.address}}</div>
                <div class="Function">
                  <!-- 暂时隐藏 -->
                  <!-- <span class="set" @click="shopEdit">编辑</span>
                  <span class="set" @click="DataCompletion">&nbsp;资料补全</span>-->
                </div>
              </div>
              <div>联系人电话：{{shopInf.mobile}}</div>
              <div>创建时间：{{shopInf.createTime}}</div>
              <div style="margin-bottom:50px">备注：{{shopInf.remark}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 收款渠道 -->
      <el-col :span="20">
        <el-card shadow="hover">
          <el-table :data="passTableData" style="width: 100%" :row-class-name="tableRowClassName">
            <el-table-column label="通道">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.type }}</span>
              </template>
            </el-table-column>
            <el-table-column label="进件状态">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.accountState }}</span>
              </template>
            </el-table-column>
            <el-table-column label="认证状态">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.wxAuthState }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开通状态">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.state=='inited'?'已开通':'未开通' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  plain
                  @click="initState(scope.row.type)"
                  :disabled="scope.row.state=='inited'"
                >开通</el-button>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  v-if="scope.row.type!=='支付宝直连'"
                  @click="getCommitUrl(scope.row.type)"
                >进件</el-button>
                <el-button
                  size="mini"
                  type="warning"
                  plain
                  v-if="scope.row.type=='标准支付'"
                  @click="searchMoney(scope.row)"
                >打款查询</el-button>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  v-if="scope.row.type=='支付宝直连'"
                  @click="signing(scope.row)"
                >签约</el-button>
                <el-button
                  size="mini"
                  type="warning"
                  plain
                  v-if="scope.row.type=='支付宝直连'"
                  @click="QRCode(scope.row)"
                >商家授权</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!-- 编辑商户弹窗开始 -->
    <el-dialog title="编辑商户" :visible.sync="ManagementEdit" width="30%">
      <el-form :model="shopInf">
        <el-form-item label="名称" :label-width="formLabelWidth">
          <el-input v-model="shopInf.shopName" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input v-model="shopInf.mobile" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="详情" :label-width="formLabelWidth">
          <el-input v-model="shopInf.remark" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="地址" :label-width="formLabelWidth">
          <el-input v-model="shopInf.address" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="密钥" :label-width="formLabelWidth">
          <el-input v-model="shopInf.secretKey" auto-complete="off" disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ManagementEdit = false">取 消</el-button>
        <el-button type="primary" @click="sureEdits">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑商户弹窗开始 -->
    <!-- 资料补全弹框开始 -->
    <el-dialog title="提交历史" :visible.sync="dialogTabledata" width="30%" center>
      <div
        style="display:flex;justify-content: space-around"
        v-for="(item,index) in Datalist"
        :key="index"
      >
        <p style="border-bottom:1px solid;padding-bottom:5px;">{{item.fileName}}</p>
        <p>{{item.createTime}}</p>
        <p class="down" @click="downloadData(item)">下载</p>
      </div>
      <div>
        <el-upload
          ref="upload"
          class="upload-demo"
          action="/api/shop/uploadMaterial"
          :file-list="fileList"
          :auto-upload="false"
          :limit="1"
          accept=".xlsx, .txt, .docx"
          :data="uploadData"
          :with-credentials="true"
          :headers="headers"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传xlsx,txt,docx格式的文件</div>
        </el-upload>
        <el-input
          v-model="fileInput"
          size="mini"
          placeholder="请添加备注"
          style="width:34%;margin-top: 5px; "
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTabledata = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">提交</el-button>
      </span>
    </el-dialog>
    <!-- 资料补全弹框结束 -->
    <!-- 支付功能开通 -->
    <el-dialog title="开通" :visible.sync="payState" width="30%">
      <el-alert
        :title="errorTitle"
        type="error"
        center
        effect="dark"
        style="margin-bottom:20px"
        v-if="isError"
      ></el-alert>
      <div style="display:flex;">
        <el-form :model="formPay" :rules="rules" ref="formPay">
          <el-form-item label="联系人名字" label-width="100px" prop="linkName">
            <el-input v-model="formPay.linkName" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="联系人电话" label-width="100px" prop="linkPhone">
            <el-input v-model="formPay.linkPhone" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="业态类型" label-width="100px" prop="operationType">
            <el-select v-model="formPay.operationType" placeholder="请选择" style="width:100%">
              <el-option
                v-for="(item,i) in operationTypeAll"
                :key="i"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="省份城市地区" label-width="110px" prop="province">
            <div style="display:flex">
              <el-select
                v-model="formPay.province"
                placeholder="请选择"
                @change="getCityAll(formPay.province)"
              >
                <el-option
                  v-for="(item,i) in provinceAll"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select
                v-model="formPay.city"
                placeholder="请选择"
                @change="getCountAll(formPay.city)"
              >
                <el-option v-for="(item,i) in cityAll" :key="i" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select v-model="formPay.area" placeholder="请选择" @change="selectCount">
                <el-option
                  v-for="(item,i) in countyAll"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="关联类型" label-width="100px">
            <el-radio-group v-model="formPay.initType">
              <el-radio :label="1">新增进件商户</el-radio>
              <el-radio :label="2">关联已进件商户</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="BOSS电话"
            label-width="100px"
            v-if="formPay.initType==1"
            prop="bossMobile"
          >
            <el-input v-model="formPay.bossMobile" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="已进件商户编号"
            label-width="120px"
            v-if="formPay.initType==2"
            prop="shopId2"
          >
            <el-input v-model="formPay.shopId2" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="payState = false">取 消</el-button>
        <el-button type="primary" @click="confirmOpenPay('formPay')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 支付功能开通结束 -->

    <!-- 表格结束 -->
    <el-dialog title="打款查询" :visible.sync="dialogVisible" width="50%">
      <el-form ref="formMoney" :model="formMoney" label-width="80px">
        <el-form-item label="查询时间">
          <el-date-picker
            v-model="formMoney.time"
            type="date"
            value-format="timestamp"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            style="width:100%"
          ></el-date-picker>
        </el-form-item>
        <div>
          <el-table :data="MoneyRecord">
            <el-table-column property="bank_holder" label="开户人"></el-table-column>
            <el-table-column property="bank_account" label="银行账号"></el-table-column>
            <el-table-column property="amount" label="打款金额"></el-table-column>
            <el-table-column property="fee" label="手续费"></el-table-column>
            <el-table-column property="sett_type" label="结算类型"></el-table-column>
            <el-table-column property="sett_date" label="结算日期"></el-table-column>
            <el-table-column property="remark" label="打款描述"></el-table-column>
            <el-table-column property="state" label="状态"></el-table-column>
          </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="confirmSearch">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 二维码弹框开始 -->
    <el-dialog
      title="商家支付宝扫码"
      :visible.sync="centerDialogVisible"
      top="15%"
      width="20%"
      center
      @close="close"
    >
      <div id="shopQrcode" ref="shopQrcode"></div>
      <div class="upDown" @click="upDown">下载</div>
    </el-dialog>
    <!--二维码弹框结束 -->
    <!-- 微信进件组件开始 -->
      <WxDetails :dialogWxDetails="dialogWxDetails" @getDetailsInformation="getDetailsInformation" @coloseWxDialog="coloseWxDialog" :shopInf="shopInf" :applyWxTb="applyWxTb"/>
    <!-- 微信进件组件结束 -->
      
  </div>
</template>

<script>
import WxDetails from './components/wxDetails.vue'
import QRCode from "qrcodejs2";
import { getList } from "@/api/table";
import { getToken } from "../../utils/auth.js";
export default {
  components:{
    WxDetails
  },
  data() {
    return {
      shopInf: {}, //商户信息
      ManagementEdit: false, //编辑弹框
      formLabelWidth: "50px",
      dialogTabledata: false, //资料补全
      Datalist: [],
      fileList: [], //上传文件列表
      headers: {
        token: getToken().Token
      },
      fileInput: "", //上传文件输入框value

      ali: "", //支付开通状态
      wx: "",
      yz: "",

      payState: false, //开通支付弹框控制
      formPay: {
        //开通支付表单值
        mchName: "",
        operationType: 8,
        recordSpaceCount: "",
        province: "",
        city: "",
        area: "",
        address: "",
        linkName: "",
        linkPhone: "",
        initType: 1,
        bossMobile: "",
        shopId2: ""
      },
      provinceAll: [], //查询的所有省份
      cityAll: [], //查询的所有市区
      countyAll: [], //查询的所有县
      operationTypeAll: [
        { name: "居民小区", value: 1 },
        { name: "商务楼宇", value: 2 },
        { name: "路侧", value: 3 },
        { name: "公园景点", value: 4 },
        { name: "商圈", value: 5 },
        { name: "交通枢纽", value: 6 },
        { name: "市政设施", value: 7 },
        { name: "其他", value: 8 }
      ],
      accountStateAll: {
        UNINCOME: "未进件",
        AUDITING: "审核中",
        REJECTED: "已驳回",
        PASSED: "进件成功"
      },
      wxAuthStateAll: {
        UNAUTHORIZED: "未触发未认证",
        TRIGGER: "已触发未认证",
        AUTHORIZED: "已认证"
      },
      rules: {
        linkName: [
          { required: true, message: "请输入联系人名字", trigger: "blur" }
        ],
        linkPhone: [{ required: true, message: "请输入电话", trigger: "blur" }],
        bossMobile: [
          { required: true, message: "请输入电话", trigger: "blur" }
        ],
        address: [
          { required: true, message: "请输入车场地址", trigger: "blur" }
        ],
        shopId2: [{ required: true, message: "请输入商户号", trigger: "blur" }],
        mchName: [{ required: true, message: "请输入车场名", trigger: "blur" }],
        recordSpaceCount: [
          { required: true, message: "请输入车位数", trigger: "blur" }
        ],
        operationType: [
          { required: true, message: "请选择业态类型", trigger: "change" }
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" }
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
        province: [
          { required: true, message: "请填写活动形式", trigger: "blur" }
        ]
      },
      passTableData: [
        {
          type: "标准支付",
          accountState: "",
          wxAuthState: "",
          state: ""
        },
        {
          type: "微信直连",
          accountState: "",
          wxAuthState: ""
        },
        {
          type: "支付宝直连",
          accountState: "",
          wxAuthState: "", //微信实名认证状态
          state: ""
        }
      ],
      errorTitle: "", //错误提示内容
      isError: false, //是否显示错误提示

      dialogVisible: false, //打款查询弹框
      serachMoneyObj: {}, //需要查询打款的
      formMoney: { time: "" },
      MoneyRecord: [], //查询的打款记录
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        }
      },

      centerDialogVisible:false,

      dialogWxDetails:false,//微信进件弹框

      applyWxTb:{},
      stateAll:{
         'FAIL':'打款失败',
         'ONGOING':'打款中',
         'SUCCESS':'打款成功',
         'REFUND':'打款退票',
         'FROZEN':'冻结出款'    
      }
    };
  },
  created() {
    this.getmerchant();
    this.getCommitState();
  },
  computed: {
    uploadData() {
      //文件上传数据
      return {
        projectId: this.shopInf.projectContractorId,
        shopId: this.shopInf.id,
        remark: this.fileInput
      };
    }
  },
  methods: {
    // 获取商户
    getmerchant() {
      this.$axios.post("shop/queryByUser").then(res => {
        if (res.data.state === 1) {
          this.shopInf = res.data.shop;
          if (this.shopInf.createTime !== undefined) {
            this.shopInf.createTime = this.getTiem(this.shopInf.createTime);
          }
          // 获取商户所有收款渠道
          this.$axios
            .get("/shop/mch", {
              params: {
                shopId: this.shopInf.id,
                projectId: this.shopInf.projectContractorId
              }
            })
            .then(res => {
              if (res.data.state == 1) {
                const data = res.data.mchsTbPage;
                this.ali = data.ali;
                this.wx = data.wx;
                this.passTableData[0].state = data.yz;
                this.passTableData[1].state = data.wx;
                this.passTableData[2].state = data.ali;
              }
            });
        }
      });
    },
    // 编辑
    shopEdit() {
      this.ManagementEdit = true;
    },
    // 确认编辑
    sureEdits() {
      const {
        shopName,
        mobile,
        remark,
        address,
        id,
        projectContractorId,
        secretKey
      } = this.shopInf;
      this.$axios
        .get("/shop/edit", {
          params: {
            shopId: id,
            projectId: projectContractorId,
            remark: remark,
            secretKey: secretKey,
            mobile: mobile,
            address,
            shopName: shopName
          }
        })
        .then(res => {
          if (res.data.state == 1) {
            this.DeleteSuccess();
            this.getmerchant();
            this.ManagementEdit = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    //资料补全
    DataCompletion() {
      const { id, projectContractorId } = this.shopInf;
      this.dialogTabledata = true;
      this.$axios
        .get("/shop/queryMaterialLog", {
          params: { shopId: id, projectId: projectContractorId }
        })
        .then(res => {
          this.Datalist = res.data.shopMaterialTbPage.map(item => {
            item.createTime = this.getTiem(item.createTime);
            return item;
          });
        });
    },
    //下载
    downloadData(item) {
      this.$axios
        .get("/shop/downloadMaterial", {
          params: {
            shopId: item.shopId,
            projectId: item.projectContractorId,
            fileId: item.id
          },
          responseType: "blob"
        })
        .then(res => {
          const blob = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          let _fileName = res.headers["content-disposition"]
            .split(";")[1]
            .split("=")[1]
            .split(".")[0];
          // 文件名中有中文 则对文件名进行转码
          a.download = decodeURIComponent(_fileName);
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        });
    },
    // 文件上传
    submitUpload() {
      this.$refs.upload.submit();
    },

    // 标准支付开通
    initState(type) {
      if (type === "标准支付") {
        this.payState = true;
        this.getaddress(1);
      }else if(type === "支付宝直连" || type === "微信直连"){
        let url='';
        if(type === "支付宝直连"){
          url='/mchali/create';
        }else if(type === "微信直连"){
           url='/mchwx/create';
        }
        const {id,projectContractorId}=this.shopInf;
        this.$axios.get(url,{params:{shopId:id,projectId:projectContractorId}})
        .then(res=>{
          if(res.data.state==1){
            this.getmerchant();
            this.DeleteSuccess();
          }else{
            this.DeleteFailure(res.data.msg);
          }
        })
      }
    },
    // 查询省对应的城市
    getCityAll(data) {
      this.formPay.city = "";
      this.getaddress(2, data);
    },
    // 查询市对应的县
    getCountAll(data) {
      this.formPay.area = "";
      this.getaddress(3, data);
    },
    // 重置选择框
    selectCount() {
      this.$forceUpdate();
    },
    // 确认开通
    confirmOpenPay(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { shopId, projectContractorId } = JSON.parse(
            sessionStorage.getItem("user")
          );
          const {
            operationType,
            province,
            city,
            area,
            linkName,
            linkPhone,
            initType,
            bossMobile,
            shopId2
          } = this.formPay;
          let params = {
            operationType,
            province,
            city,
            area,
            linkName,
            linkPhone,
            initType,
            bossMobile,
            shopId2,
            shopId,
            projectId: projectContractorId
          };
          this.$axios.get("/mchyz/init", { params }).then(res => {
            if (res.data.state == 1) {
              this.getmerchant();
              this.payState = false;
              this.$message({ message: res.data.msg, type: "success" });
            } else {
              this.isError = true;
              this.errorTitle = res.data.desc;
              this.$message({ message: res.data.msg, type: "error" });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取进件状态
    getCommitState() {
      const { shopId, projectContractorId } = JSON.parse(
        sessionStorage.getItem("user")
      );
      this.$axios
        .get("/mchyz/commitState", {
          params: { shopId, projectId: projectContractorId }
        })
        .then(res => {
          this.passTableData[0].accountState = this.accountStateAll[
            res.data.account_state
          ];
          this.passTableData[0].wxAuthState = this.wxAuthStateAll[
            res.data.wx_auth_state
          ];
        });
    },
    // 进件
    getCommitUrl(type) {
      if (type === "标准支付") {
        const { shopId, projectContractorId } = JSON.parse(
          sessionStorage.getItem("user")
        );
        this.$axios
          .get("/mchyz/commitUrl", {
            params: { shopId, projectId: projectContractorId }
          })
          .then(res => {
            if (res.data.state == 1) {
              window.open(res.data.url);
            } else {
              this.$message({ message: res.data.msg, type: "error" });
            }
          });
      }else if(type === "微信直连"){
        this.getDetailsInformation();
        this.dialogWxDetails=true;
      }
    },
    // 获取进件信息
    getDetailsInformation(){
      const { id, projectContractorId } = this.shopInf; //点击的支付宝目标
      this.$axios.get('/mchwx/applyDetails',{params:{shopId:id,projectId:projectContractorId}}).then(res=>{
        if(res.data.state==1){
          this.applyWxTb=res.data.applyWxTb;
        }
      })
    },
    // 关闭微信进件弹框
    coloseWxDialog(){
      this.dialogWxDetails=false;
    },
    // 查询地址
    getaddress(level, pid) {
      this.$axios
        .get("/mchyz/queryAreaId", { params: { level, pid } })
        .then(res => {
          if (res.data.state == 1) {
            if (level == 1) {
              this.provinceAll = res.data.areaIds;
            } else if (level == 2) {
              this.cityAll = res.data.areaIds;
            } else {
              this.countyAll = res.data.areaIds;
            }
          }
        });
    },

    // 打款查询
    searchMoney(row) {
      this.MoneyRecord = [];
      this.dialogVisible = true;
    },
    // 确认查询
    confirmSearch() {
      const { id, projectContractorId } = this.shopInf;
      const { time } = this.formMoney;
      let paramsData = {
        shopId: id,
        projectId: projectContractorId,
        timeStamp: time / 1000
      };
      this.$axios.get("/mchyz/tCashQuery", { params: paramsData }).then(res => {
        if (res.data.state == 1) {
          this.$message({
            message: res.data.msg + "," + res.data.desc,
            showClose: true,
            type: "success"
          });
          let data = res.data.biz_content.datas.map(item => {
            item.amount = item.amount / 100;
            item.state = this.stateAll[item.state];
            return item;
          });
          this.MoneyRecord = [...data, ...this.MoneyRecord];
        } else {
          this.$message({
            message: res.data.msg + "," + res.data.desc,
            showClose: true,
            type: "error"
          });
        }
      });
    },
    //查看二维码
    QRCode() {
      this.centerDialogVisible = true;
      const { id, projectContractorId } = this.shopInf;
      this.$axios
        .get("mchali/getAuthUrl", {
          params: { shopId:id, projectId: projectContractorId }
        })
        .then(res => {
          if (res.data.state === 1) {
            const url = res.data.url;
            new QRCode("shopQrcode", {
              width: 120,
              height: 120, // 高度
              correctLevel: 3,
              text: url, // 二维码内容
              render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
              background: "#f0f",
              foreground: "#ff0"
            });
          }else{
            this.DeleteFailure(res.data.msg)
          }
        });
    },
    //关闭二维码弹框
    close() {
      document.querySelector("#shopQrcode").innerHTML = "";
    },
    // 下载二维码
    upDown() {
      // //找到canvas标签
      let myCanvas = document
        .getElementById("shopQrcode")
        .getElementsByTagName("canvas");
      let img = document.getElementById("shopQrcode").getElementsByTagName("img");
      //创建一个a标签节点
      let a = document.createElement("a");
      //设置a标签的href属性（将canvas变成png图片）
      let imgURL = myCanvas[0].toDataURL("image/jpg");
      let ua = navigator.userAgent;
      if (ua.indexOf("Trident") != -1 && ua.indexOf("Windows") != -1) {
        // IE内核 并且  windows系统 情况下 才执行;
        var bstr = atob(imgURL.split(",")[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, "下载" + "." + "png");
      } else if (ua.indexOf("Firefox") > -1) {
        //火狐兼容下载
        let blob = this.base64ToBlob(imgURL);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        a.download = "二维码"; //下载图片名称，如果填内容识别不到，下载为未知文件
        a.href = URL.createObjectURL(blob);
        a.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
          })
        ); //兼容火狐
      } else {
        //谷歌兼容下载
        img.src = myCanvas[0].toDataURL("image/jpg");
        a.href = img.src;
        //设置下载文件的名字
        a.download = "二维码";
        //点击
        a.click();
      }
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    // 签约
    signing(row){
      const {id,projectContractorId} = this.shopInf;
      this.$axios.get('/mchali/getShopSelfSignUrl',{params:{shopId:id,projectId:projectContractorId}})
      .then(res=>{
        if(res.data.state==1){
          window.open(res.data.url);
        }
      })
    },
    //删除成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //删除失败提示
    DeleteFailure(data) {
      this.$message.error(data);
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          +this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>
<style lang="scss" scoped>
/* 商户信息开始 */
.header {
  display: flex;
  margin-left: 3%;
  margin-right: 5%;
  justify-content: space-between;
}
.Function {
  display: flex;
}
/* 商户信息结束 */
/* 收款渠道开始 */
.Receivables {
  width: 92.3%;
  height: 80%;
  margin-left: 2.9%;
  margin-top: 1%;
  padding-top: 5px;
  box-shadow: 2px 2px 10px #ccc;
  border-radius: 10px;
}
.Receivables > p {
  margin-left: 20px;
  font-weight: 600;
}
.Payment {
  width: 100%;
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
}
.Payment > div {
  width: 100%;
  margin-bottom: 10px;
}
.Payment > div > img {
  width: 6%;
}
.wechat {
  padding-left: 10px;
  padding-top: 10px;
  width: 90%;
}

.wechat > div {
  display: flex;
  justify-content: space-between;
}
.wechat > div > div {
  margin-bottom: 5px;
}
.aplay {
  margin-bottom: 31%;
  margin-top: 30%;
  margin-left: 30%;
}
.set:hover {
  color: blue;
}

.el-dialog__title {
  color: white !important;
  font-weight: 600;
  font-size: 22px;
}
.down:hover {
  color: blue;
}
.headers_name {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}

.engineer {
  width: 100%;
  padding: 20px;
  background-color: #ecf5f9;
}
.engineer > div:nth-child(2) {
  margin-left: 4%;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}
.engineer > div:first-child {
  font-weight: 600;
  margin-bottom: 25px;
  font-size: 18px;
}
.engineer > div > p {
  font-size: 14px;
  font-weight: 600;
}
.pass {
  display: flex;
  justify-content: space-between;
}
.yzpay > span {
  margin-left: 5px;
}
.infromations > div {
  margin-bottom: 10px;
}

#shopQrcode {
  width: 120px;
  height: 150px;
  margin: 10px auto 0;
}
.upDown{
  text-align: right;
}
</style>
